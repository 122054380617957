
import { mapState } from 'vuex';
import { defineComponent } from 'vue';
import { showError, showSuccess } from '@/sweetalert2/templates.ts';
import { CommonError, Superlearning, User, errorPrepend } from '@/models';
import { Settings } from '@/models/Settings';

export default defineComponent({
  data () {
    return {
      passwords: {
        old: '', new1: '', new2: ''
      },
      userSettings: {} as Settings,
      allAudios: [] as Superlearning[]
    };
  },
  computed: {
    ...mapState({ user: 'user' })
  },
  async mounted () {
    try {
      this.userSettings = await Settings.getUserSettings();
      if (this.user.type === 'student') { this.allAudios = await Superlearning.getAll(); }
    } catch (e) {
      await showError(this.$t("messages.error load settings") + errorPrepend());
    }
  },
  methods: {
    async saveSettings () {
      try {
        await Settings.update(this.userSettings);
        await this.$store.dispatch('getSettings');
        await showSuccess(this.$t("messages.settings saved"));
      } catch (e) {
        await showError(this.$t("messages.error save settings") + errorPrepend());
      }
    },
    async changePassword () {
      if (!this.validateInputs()) {
        return;
      }

      try {
        await User.updatePassword(this.passwords);
        await showSuccess(this.$t("messages.password changed"));
        this.passwords = { old: '', new1: '', new2: '' };
      } catch (e) {
        await showError(this.$t("messages.no password change") + errorPrepend());
      }
    },
    validateInputs (): boolean {
      if (Object.values(this.passwords).some(s => s.length === 0)) {
        showError(this.$t("messages.fill all fields"));
        return false;
      }
      if (this.passwords.new1 !== this.passwords.new2) {
        showError(this.$t("messages.password matching"));
        return false;
      }
      return true;
    }
  }
});
