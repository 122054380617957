import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid px-xl-5" }
const _hoisted_2 = { class: "pt-5" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "col-xl-6 col-md-9" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "h6 text-uppercase mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChangePasswordForm = _resolveComponent("ChangePasswordForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("messages.your profile")), 1)
            ]),
            _createVNode(_component_ChangePasswordForm)
          ])
        ])
      ])
    ])
  ]))
}